import L from "leaflet";



var iconeRed = L.icon({
  iconSize: [25, 25],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "assets/icones/marker-red.png"
})

export default iconeRed;

